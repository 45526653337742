import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const TermsCondition = lazy(() => import('../../container/pages/TermsComditions'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="settings/*" element={<Settings />} />
      <Route path="termCondition" element={<TermsCondition />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
