/* eslint-disable react/jsx-no-constructed-context-values */
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import { extractToken } from './config/dataService/dataService';

const reducer = (state = { value: '' }, action) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, value: action.payload };
    default:
      return state;
  }
};

const store = createStore(reducer);

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

const themeConfig = {
  rtl: config.rtl,
  topMenu: config.topMenu,
  mainContent: config.mainTemplate,
  loading: false,
  rtlLoading: false,
  menuLoading: false,
  mainContentLoading: false,
  error: null,
};

const isLoggedIn = extractToken();

function ProviderConfig() {
  const { rtl, topMenu, mainContent } = themeConfig;
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <Provider store={store}>
        <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
          <Router>
            {!isLoggedIn ? (
              <Routes>
                <Route path="/*" element={<Auth />} />{' '}
              </Routes>
            ) : (
              <Routes>
                <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {isLoggedIn && path === '/' && (
              <Routes>
                <Route path="/" element={<Navigate to="/admin" />} />
              </Routes>
            )}
          </Router>
        </ThemeProvider>
      </Provider>
    </ConfigProvider>
  );
}

function App() {
  return <ProviderConfig />;
}

export default App;
