import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

export const getApiUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_BASE_URL_PROD;
  }

  return process.env.REACT_APP_API_BASE_URL_DEV;
};

const API_ENDPOINT = `${getApiUrl()}/api`;

// const token = secureLocalStorage.getItem('needs') ? secureLocalStorage.getItem('needs').token : null;

export const getAuthUserData = () => {
  return secureLocalStorage.getItem('needs') ? secureLocalStorage.getItem('needs') : null;
};

export const extractToken = () => {
  return secureLocalStorage.getItem('needs') ? secureLocalStorage.getItem('needs').token : null;
};

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${extractToken()}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

class DataService {
  static get(path = '', data = {}) {
    return client({
      method: 'GET',
      url: path,
      data,
      headers: { Authorization: `Bearer ${extractToken()}` },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { Authorization: `Bearer ${extractToken()}`, ...optionalHeader },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data,
      headers: { Authorization: `Bearer ${extractToken()}` },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { Authorization: `Bearer ${extractToken()}` },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { Authorization: `Bearer ${extractToken()}` },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${extractToken()}` };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);
export { DataService };
